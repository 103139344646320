import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, FormControlDirective } from '@angular/forms';
import { PcUsuarioLogadGuard } from '../shared/guards/default.guard';
import { ApetiteRiscoComponent } from './apetite-risco/apetite-risco.component';
import { ApetiteRiscoListagemComponent } from './apetite-risco-listagem/apetite-risco-listagem.component';
import { DadosGeraisComponent } from './apetite-risco/dados-gerais/dados-gerais.component';
import { SiconvModule } from '../siconv.module';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    FormsModule,
    SiconvModule
  ],
  providers: [
    FormControlDirective, PcUsuarioLogadGuard],
  exports: [],
  declarations: [
    ApetiteRiscoComponent,
    ApetiteRiscoListagemComponent,
    DadosGeraisComponent
  ]
})
export class PagesModule { }
