import { FileUtil } from './util/file-util';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { NgModule } from '@angular/core';

import { MenuComponent } from './components/menu/menu.component';
import { LimitarTextoPipe } from './pipes/limitar-texto.pipe';
import { CpfPipe } from './pipes/cpf.pipe';

import { InputMaskModule } from 'primeng/components/inputmask/inputmask';

import { DateFormatBrPipe } from './pipes/date-format-br.pipe';
import { BootstrapModule } from './bootstrap.module';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MenuModule } from './components/menu/menu.module';
import { NgSelectModule, NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilToken } from './util';
import { ZeroEsquerdaPipe } from './pipes/zero-esquerda.pipe';
import { CpsCurrencyPipe } from './pipes/currency.pipe';
import { ModalContentComponent } from './components/modal/modal.component';
import { SiconvDatePipe } from './pipes/siconv-date.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { SelectOrgaoPipe, SelectAtoPipe } from './pipes/selects.pipe';
import { ValueIdPipe } from './pipes/values.pipe';


@NgModule({
  imports: [
    BootstrapModule,
    CommonModule,
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MenuModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    FileUploadModule
  ],
  exports : [
    BootstrapModule,
    CommonModule,
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MenuComponent,
    ModalContentComponent,
    MenuModule,
    NgSelectModule,
    BrowserAnimationsModule,
    BooleanPipe,
    CpfPipe,
    DateFormatBrPipe,
    SelectOrgaoPipe,
    SelectAtoPipe,
    ValueIdPipe,
    SiconvDatePipe,
    LimitarTextoPipe,
    ZeroEsquerdaPipe,    
    CpsCurrencyPipe,
    FileUploadModule
  ],
  declarations: [
    BooleanPipe,
    CpfPipe,
    DateFormatBrPipe,
    SelectOrgaoPipe,
    SelectAtoPipe,
    ValueIdPipe,
    SiconvDatePipe,
    LimitarTextoPipe,
    ZeroEsquerdaPipe,
    CpsCurrencyPipe,    
    MenuComponent,
    ModalContentComponent
  ],
  providers: [
    UtilToken,
    DecimalPipe,
    FileUtil,
    {
      provide: NG_SELECT_DEFAULT_CONFIG,
      useValue: {
          notFoundText: 'Item não encontrado'
      }
    },
  ],
  entryComponents: [ModalContentComponent]
})
export class SharedModule { }
