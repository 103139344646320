import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalidadeConvenioTipo } from '../../shared/models';
import { PcHttpClient } from './pc-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class ModalidadeConvenioTipoService {

  constructor(private http: PcHttpClient, private settings: SettingsService) {}

  getModalidadeConvenioTipos(): Observable<ModalidadeConvenioTipo[]> {
    return this.http.get(this.settings.UrlPcRest() + 'api/modalidade');
  }

  getModalidadeConvenioTipo(id: number): Observable<ModalidadeConvenioTipo> {
    return this.http.get(this.settings.UrlPcRest() + 'api/modalidade/' + id);
  }
}
