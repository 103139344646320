import { EventButtonService } from './../../../core/services/event-button.service';
import { FileUtil } from './../../../shared/util/file-util';
import { ApetiteRiscoAnexo } from '../../../shared/models/apetite-risco-anexo';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap';
import { ApetiteRiscoService, IdpService, OrgaoService, AtoNormativoTipoService } from '../../../core/services';
import { ApetiteRisco, Orgao, Usuario, ModalidadeConvenioTipo, FaixaRisco, ApetiteRiscoModalidade, ApetiteFaixaRisco, AtoNormativoTipo } from '../../../shared/models';
import { FaixaRiscoService } from '../../../core/services/faixa-risco.service';
import { ModalidadeConvenioTipoService } from '../../../core/services/modalidade-convenio-tipo.service';
import { AlertMessageService } from '@serpro/ngx-siconv';
import { CpsCurrencyPipe } from '../../../shared/pipes/currency.pipe';

@Component({
  selector: 'apetite-dados-gerais',
  templateUrl: './dados-gerais.component.html',
  styleUrls: ['./dados-gerais.component.scss']
})
export class DadosGeraisComponent implements OnInit, OnDestroy  {
  
  @Input() modoEdicao: boolean = true;
  @Output() setAnexos = new EventEmitter();
  
  form: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;

  listaOrgaos: any[] = new Array();
  listaTiposAtoNormativo: any[] = new Array();
  nomeAtoNormativoVisible: boolean = false;

  usuarioLogado: Usuario;
  idApetite: number;
  
  isAnexando: Boolean = false;
  anexos: ApetiteRiscoAnexo[] = new Array();
  anexoAtual: ApetiteRiscoAnexo;
  anexoDescricao: string = '';

  instrumentosSelecionados = [];
  instrumentosSource = [];
  instrumentosTarget = [];

  apetiteFaixaRiscos: ApetiteFaixaRisco[] = new Array();

  constructor(
    private formBuilder: FormBuilder,
    private orgaoService: OrgaoService,
    private atoNormativoTipoService: AtoNormativoTipoService,
    private apetiteService: ApetiteRiscoService,
    private faixaRiscoService: FaixaRiscoService,
    private modalidadeService: ModalidadeConvenioTipoService,
    private idpService: IdpService,
    private mensageService: AlertMessageService,
    private fileUtil: FileUtil,
    private localeService: BsLocaleService) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.localeService.use('pt-br');
    EventButtonService.get('salvarApetite').subscribe(data => { if (data) { this.carregarAnexos(); this.isAnexando = false; } });    
  }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.getForm();
    this.usuarioLogado = this.idpService.loggedUser;        

    if (this.apetiteService.apetiteCorrente) {
      this.recuperarApetiteCorrente();
      this.carregarAnexos();      
    }

    this.carregarOrgaoSuperior();
    this.carregarOrgaos();
    this.carregarAtoNormativoTipos();
    this.carregarTiposInstrumentos();

    this.carregarFaixaRiscos();

    this.form.valueChanges.subscribe(() => {
      this.atualizarApetiteCorrente();
    });    
  }

  ngOnDestroy() {
    EventButtonService.unsubscribe('salvarApetite');
  }

  orgaoSuperiorCtrl: FormControl = new FormControl('', [Validators.maxLength(10)]); //Validators.required
  orgaoCtrl: FormControl = new FormControl('', [Validators.maxLength(10)]);
  douSecaoCtrl: FormControl = new FormControl('', [Validators.maxLength(10)]);
  douPaginaCtrl: FormControl = new FormControl('', [Validators.maxLength(10)]);
  douProcessoCtrl: FormControl = new FormControl('', [Validators.maxLength(20)]);
  douDtPublicacaoCtrl: FormControl = new FormControl('');
  atoNumeroCtrl: FormControl = new FormControl('', [Validators.maxLength(10)]);
  atoTipoCtrl: FormControl = new FormControl('', [Validators.maxLength(17)]);
  atoNomeCtrl: FormControl = new FormControl('', [Validators.maxLength(120)]);
  atoDtPublicacaoCtrl: FormControl = new FormControl('');
  fileCtrl: FormControl = new FormControl('');

  getForm(): void {
    this.form = this.formBuilder.group({      
      orgaoSuperior: this.orgaoSuperiorCtrl,     
      orgao: this.orgaoCtrl,
      douSecao: this.douSecaoCtrl,
      douPagina: this.douPaginaCtrl,
      douProcesso: this.douProcessoCtrl,
      douDtPublicacao: this.douDtPublicacaoCtrl,
      atoNumero: this.atoNumeroCtrl,
      atoTipo: this.atoTipoCtrl,
      atoNome: this.atoNomeCtrl,
      atoDtPublicacao: this.atoDtPublicacaoCtrl,
      file: this.fileCtrl,
      dataCriacao: ['', Validators.required]
    });
    
    this.campoBloqueado();
  }

  podeManterApetite(): boolean {
    if (this.usuarioLogado) {
      return this.usuarioLogado.isGestorConvenioConcedente() || this.usuarioLogado.isGestorFinanceiroConcedente()
    || this.usuarioLogado.isOperacionalFinanceiroConcedente() || this.usuarioLogado.isOperacionalConcedente();
    } else {
      return false;
    }    
  }

  campoBloqueado() {        
    if (this.podeManterApetite() && this.modoEdicao) {
      this.form.enable();
      this.form.controls['orgaoSuperior'].disable();
      if(this.idApetite){
        this.form.controls['orgao'].disable();
        this.form.controls['dataCriacao'].disable();
      }
      return null;
    } else {
      this.form.disable();
      return true;
    }
  }

  carregarOrgaoSuperior() {
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;    
    if (apetite.id == 0 || apetite.id == null) {
      this.recuperarOrgaoSuperiorUsuario();
    } else {
      this.recuperarOrgaoSuperiorApetite(apetite.orgao);
    }
  }

  private carregarOrgaos(): void {   
    let orgao: Orgao; 
    if (this.usuarioLogado) {
      orgao = this.usuarioLogado.orgao;
      if (!orgao.id) {
        this.mensageService.warn('Usuário não associado', 
        'O usuario não está associado a nenhum orgão.');
      }
    } else {
      const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;
      orgao = apetite.orgao;
    }

    if (orgao.id) {
      this.orgaoService.getSubordinados(orgao.codigo).subscribe(
        (response: Orgao[]) => {
          this.listaOrgaos = response;
        }
      );    
    }
  }

  private carregarAtoNormativoTipos(): void {   
    this.atoNormativoTipoService.getAtoNormativoTipos().subscribe(
      (response: AtoNormativoTipo[]) => {
        this.listaTiposAtoNormativo = response;
      }
    );   
  }

  carregarTiposInstrumentos(){
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;
    let listaInstrumentos = new Array();
    let listaInstrumentosSelecionados = new Array();
    this.modalidadeService.getModalidadeConvenioTipos().subscribe(      
      (response: ModalidadeConvenioTipo[]) => {
        response.map(instrumento => {            
          listaInstrumentos.push(instrumento);        
        });          
        if (apetite.id != null && apetite.id != undefined) {   
          listaInstrumentos.map(instrumento => {                
            if(apetite.modalidades.find(x => x.modalidadeConvenioTipo.id === instrumento.id)){              
              this.instrumentosTarget.push({ name: instrumento.tipo, data: instrumento });  
              listaInstrumentosSelecionados.push(new ApetiteRiscoModalidade(this.idApetite, instrumento));            
            }else {
              this.instrumentosSource.push({ name: instrumento.tipo, data: instrumento });              
            }            
          });
        } else {
          listaInstrumentos.map(instrumento => {            
            this.instrumentosSource.push({ name: instrumento.tipo, data: instrumento });   
          });
        }          
        apetite.modalidades = new Array();
        apetite.modalidades = listaInstrumentosSelecionados;
        this.apetiteService.apetiteCorrente = apetite;
      }      
    );
  }

  async carregarFaixaRiscos() {    
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;        
    this.apetiteFaixaRiscos = new Array();  
    let listaFaixaRisco = new Array();    
    if (apetite.id != null && apetite.id != undefined) {
      await this.faixaRiscoService.getFaixasRiscos().subscribe(
        (response: FaixaRisco[]) => {          
          response.map(faixa => {            
            listaFaixaRisco.push(faixa);        
          });   
          listaFaixaRisco.map(faixa => {    
            if(!apetite.apetiteFaixaRiscos.find(x => x.faixaRisco.id === faixa.id)){
              apetite.apetiteFaixaRiscos.push(new ApetiteFaixaRisco(this.idApetite, faixa, 0));
            }               
          });   
          this.apetiteFaixaRiscos = apetite.apetiteFaixaRiscos; 
          this.apetiteService.apetiteCorrente = apetite;
        }    
      );
    } else {
      this.apetiteFaixaRiscos = apetite.apetiteFaixaRiscos; 
    }
  }

  recuperarOrgaoSuperiorApetite(orgao: Orgao) {
    let descricao = '';
    let orgaoSuperior: Orgao = null;    
    if (orgao.orgaoSuperior) {      
      this.orgaoService.getOrgao(orgao.orgaoSuperior).subscribe(
        (response: Orgao) => {
          orgaoSuperior = response;
          if (orgaoSuperior) {
            descricao = orgaoSuperior.codigo + ' - ' + orgaoSuperior.nome;
          } else {
            descricao = 'Orgão não identificado';
          }
          this.form.controls['orgaoSuperior'].setValue(descricao);
        }
      );
    } else {
      descricao = orgao.codigo + ' - ' + orgao.nome;
      this.form.controls['orgaoSuperior'].setValue(descricao);
    }    
  }

  recuperarOrgaoSuperiorUsuario() {
    let descricao = '';
    let orgaoSuperior: Orgao = null;
    if (this.usuarioLogado.orgao) {
      if (this.usuarioLogado.orgao.orgaoSuperior) {
        this.orgaoService.getOrgao(this.usuarioLogado.orgao.orgaoSuperior).subscribe(
          (response: Orgao) => {
            orgaoSuperior = response;
            if (orgaoSuperior) {
              descricao = orgaoSuperior.codigo + ' - ' + orgaoSuperior.nome;
            } else {
              descricao = 'Orgão não identificado';
            }
            this.form.controls['orgaoSuperior'].setValue(descricao);
          }
        );
      } else {
        descricao = this.usuarioLogado.orgao.codigo + ' - ' + this.usuarioLogado.orgao.nome;
        this.form.controls['orgaoSuperior'].setValue(descricao);
      }   
    } else {
      this.mensageService.warn('Usuário não associado', 
      'O usuario não está associado a nenhum orgão.');
    }
    return this.usuarioLogado.orgao;
  }

  private atualizarApetiteCorrente() {
    if (this.form.dirty) {
      const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;      
      apetite.orgao = this.listaOrgaos.find(x => x.id === this.form.controls['orgao'].value);
      apetite.douSecao = this.form.controls['douSecao'].value;
      apetite.douPagina = this.form.controls['douPagina'].value;
      apetite.douProcesso = this.form.controls['douProcesso'].value;
      apetite.douDtPublicacao = this.form.controls['douDtPublicacao'].value;
      apetite.atoNumero = this.form.controls['atoNumero'].value;
      apetite.atoTipo = this.form.controls['atoTipo'].value;
      apetite.atoNome = this.form.controls['atoNome'].value;
      apetite.atoDtPublicacao = this.form.controls['atoDtPublicacao'].value;

      this.apetiteService.apetiteCorrente = apetite;
    }
  }

  private async recuperarApetiteCorrente() {
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;
    if (apetite.id > 0) {            
      this.idApetite = apetite.id;      
      
      this.carregarOrgaoSuperior();
      if(this.modoEdicao){
        this.carregarOrgaos();
      } else {
        this.listaOrgaos = new Array();
        this.listaOrgaos.push(apetite.orgao);
      }
      this.form.controls['orgao'].setValue(apetite.orgao.id);     

      this.form.controls['douSecao'].setValue(apetite.douSecao);
      this.form.controls['douPagina'].setValue(apetite.douPagina);
      this.form.controls['douProcesso'].setValue(apetite.douProcesso);
      if (apetite.douDtPublicacao) {      
        this.form.controls['douDtPublicacao'].setValue(moment(apetite.douDtPublicacao, 'YYYY-MM-DD').toDate());
      } else {
        this.form.controls['douDtPublicacao'].setValue(null);
      }   
      
      this.form.controls['atoNumero'].setValue(apetite.atoNumero);
      this.form.controls['atoTipo'].setValue(apetite.atoTipo);

      if (apetite.atoNome != null && apetite.atoNome != undefined) {      
        this.form.controls['atoNome'].setValue(apetite.atoNome);
        this.atoNormativoChange();
      } else {
        this.form.controls['atoNome'].setValue('');        
      }

      if (apetite.atoDtPublicacao) {      
        this.form.controls['atoDtPublicacao'].setValue(moment(apetite.atoDtPublicacao, 'YYYY-MM-DD').toDate());
      } else {
        this.form.controls['atoDtPublicacao'].setValue(null);
      }   

      if(apetite.dtCriacaoF){
        this.form.controls['dataCriacao'].setValue(apetite.dtCriacaoF);
      } else {
        this.form.controls['dataCriacao'].setValue(null);                
      }
      this.setAnexos.emit(this.anexos);
    }
  }

  onPaste(e: any) {
    e.preventDefault();
  }

  onChangeLimite(item, event) {       
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;
    let limite = event.target.value;
    apetite.apetiteFaixaRiscos
    .find(x => x.faixaRisco.id === item.faixaRisco.id).valorLimiteAuxiliar = limite;
    this.apetiteService.apetiteCorrente = apetite;      
  }

  async carregarAnexos() {    
    if (this.apetiteService.apetiteCorrente.id != null && this.apetiteService.apetiteCorrente.id != undefined) {
      //setando o id apos eventemmiter de salvar
      this.idApetite = this.apetiteService.apetiteCorrente.id;
      this.form.controls['dataCriacao'].setValue(this.apetiteService.apetiteCorrente.dtCriacaoF);
      
        await this.apetiteService.listarAnexos(this.apetiteService.apetiteCorrente.id).toPromise().then(
          (response: ApetiteRiscoAnexo[]) => {
            this.anexos = new Array();
            if (response) { 
              response.map(e => {
                this.anexos.push(e);
              });
            }                    
            this.setAnexos.emit(this.anexos);   
          }        
        );
    }       
  }

  addAnexo() {
    this.isAnexando = true;
  }

  confirmarAnexo() {
    if (!this.anexoAtual) {
      this.mensageService.error('Arquivo inválido', 'Nenhum arquivo selecionado.');
    } else if (!this.anexoDescricao) {
      this.mensageService.error('Descrição obrigatória', 'A descrição do arquivo é obrigatória.');
    } else {
      this.anexoAtual.descricao = this.anexoDescricao;      
      this.anexos.push(this.anexoAtual);
      this.setAnexos.emit(this.anexos);
      this.isAnexando = false;
      this.anexoDescricao = '';
      this.fileCtrl.setValue('');
      this.anexoAtual = null;      
    }   
  }

  fileUpload(event) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (this.isArquivoValido(file)) {
        const anexo = new ApetiteRiscoAnexo();
        anexo.apetiteRiscoId = this.apetiteService.apetiteCorrente.id;
        anexo.arquivo = reader.result.toString().split(',')[1];
        anexo.descricao = this.anexoDescricao;
        anexo.nome = file.name;        
        anexo.tamanho = file.size;
        anexo.situacao = 'N';
        this.anexoAtual = anexo;
      }
    };     
    const [file] = event.target.files;
    reader.readAsDataURL(file);
  }

  isArquivoValido(file: File): boolean {
    const tiposValidos: string[] = new Array(
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet');
    let isValido = true;
    if (file.size / 1000000 > 25) {
      isValido = false;
      this.mensageService.error('Tamanho inválido', 'O tamanho máximo do arquivo é 25MB.');
    } else if (tiposValidos.indexOf(file.type) < 0) {
      isValido = false;
      this.mensageService.error('Formato do arquivo inválido', 
      'Formatos permitidos: PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, PNG, ODT e ODS.');
    }   
    return isValido;
  }

  anexoDownload(anexo) {
    if (anexo.id) {
      this.apetiteService.baixarAnexo(this.apetiteService.apetiteCorrente.id, anexo.id).subscribe(
        (response: ApetiteRiscoAnexo[]) => {
          response.map(e => {
            this.fileUtil.downloadFile(e.arquivo, e.nome);
          });
        }
      );
    } else {
      this.fileUtil.downloadFile(anexo.arquivo, anexo.nome);
    }
  }

  removerAnexo(anexo: ApetiteRiscoAnexo) {
    anexo.situacao = 'E';
    anexo.arquivo = '';
    this.setAnexos.emit(this.anexos);
  }  

  podeAnexar(): boolean {
    if (!this.apetiteService.apetiteCorrente.orgao) {
      return true;
    }
    if (this.usuarioLogado) {
      return this.usuarioLogado.orgao && 
      this.apetiteService.apetiteCorrente && 
      this.apetiteService.apetiteCorrente.orgao &&
      (this.usuarioLogado.orgao.id === this.apetiteService.apetiteCorrente.orgao.id
        || this.usuarioLogado.orgao.id === this.apetiteService.apetiteCorrente.orgao.orgaoSuperior) &&
      this.modoEdicao;
    }
    return false;
  }

  onInstrumentosChanged(event) {        
    const apetite: ApetiteRisco = this.apetiteService.apetiteCorrente;
    apetite.modalidades = new Array();
    this.instrumentosSelecionados = event;
    this.instrumentosSelecionados.forEach(element => {
      apetite.modalidades.push(new ApetiteRiscoModalidade(this.idApetite, element.data)); 
    }); 
    this.apetiteService.apetiteCorrente = apetite;
  }

  atoNormativoChange(){    
    if (this.form.controls['atoTipo'].value == 14) {
      this.nomeAtoNormativoVisible = true;
    } else {      
      this.nomeAtoNormativoVisible = false;
      this.form.controls['atoNome'].setValue('');  
    }    
  }

  public getValoresFaixaRisco(faixa: FaixaRisco) : string {     
    const pipe: CpsCurrencyPipe = new CpsCurrencyPipe();      
    return faixa.codigo + " (De R$ " 
     + (faixa.valorInicial ? pipe.transform(100* faixa.valorInicial) : '0,00')
     + " até R$ " + pipe.transform(100* faixa.valorFinal) + ")";
  
  }
}
