
    import { NgModule } from '@angular/core';
    import {
      SiconvCardModule,
      SiconvFieldsetModule,  
      SiconvDatePickerModule,
      SiconvTableModule,
      SiconvHeaderModule, 
      SiconvPipesModule,
      SiconvPickListModule,
      SiconvSecondTabModule,
      SiconvSubFieldsetModule,
      SiconvTabModule,
      SiconvTitleModule,
      SiconvVerticalTabModule,
      SiconvDirectivesModule,
      SiconvAlertMessagesModule,
      SiconvCommonModule,
      SiconvLocalMessageModule,
      SiconvInputModule,
      SiconvSelectModule,
      SiconvTextAreaModule,
      SiconvFileModule,
      SiconvSwitchModule
    } from '@serpro/ngx-siconv';

    @NgModule({
      imports: [
        SiconvAlertMessagesModule,
        SiconvCardModule,
        SiconvCommonModule,
        SiconvDatePickerModule,
        SiconvFieldsetModule, 
        SiconvFileModule,
        SiconvHeaderModule,     
        SiconvLocalMessageModule,
        SiconvInputModule,
        SiconvPickListModule,
        SiconvSecondTabModule,
        SiconvSelectModule,
        SiconvSubFieldsetModule,
        SiconvTabModule,
        SiconvTableModule,
        SiconvTextAreaModule,
        SiconvTitleModule,
        SiconvVerticalTabModule,
        SiconvDirectivesModule,
        SiconvPipesModule,
        SiconvSwitchModule
      ],
      exports: [
        SiconvAlertMessagesModule,
        SiconvCardModule,
        SiconvCommonModule,
        SiconvDatePickerModule,
        SiconvFieldsetModule, 
        SiconvFileModule,
        SiconvHeaderModule,     
        SiconvLocalMessageModule,
        SiconvInputModule,
        SiconvPickListModule,
        SiconvSecondTabModule,
        SiconvSelectModule,
        SiconvSubFieldsetModule,
        SiconvTabModule,
        SiconvTableModule,
        SiconvTextAreaModule,
        SiconvTitleModule,
        SiconvVerticalTabModule,
        SiconvDirectivesModule,
        SiconvPipesModule,
        SiconvSwitchModule
      ]
    })
    export class SiconvModule {}
    