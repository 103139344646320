
import {of as observableOf,  Observable } from 'rxjs';
import { ApetiteRiscoAnexo } from '../models/apetite-risco-anexo';

import { Usuario, Orgao } from '../models';
import { ApetiteRisco } from '../models/apetite-risco';
import { } from 'jasmine';

export class IdpServiceMock {
  constructor() { }
  get loggedUser(): Usuario {
    const orgao = new Orgao(1449, '20113', 'teste');
    return new Usuario('Bruno', '02589632587', 'bruno@gmail.com', orgao,
      ['GESTOR_CONVENIO_INSTITUICAO_MANDATARIA', 'GESTOR_CONVENIO_CONCEDENTE']);
  }

  async retoken() {
    return 'pSKLDJFN928RHM879.879H97H7yiouh87y978h9.7897Y9H9898h9GH';
  }
}

export class ApetiteRiscoServiceMock {
  constructor() { }
  

  set apetiteCorrente(apetiteRisco: ApetiteRisco) {

  }

  listaApetitePorOrgao(orgao): Observable<ApetiteRisco[]> {
    const lista: ApetiteRisco[] = new Array();
   
    return observableOf(lista);
  }

  listarAnexos(id): Observable<ApetiteRiscoAnexo[]> {
    const lista: ApetiteRiscoAnexo[] = new Array();
    lista.push(
      new ApetiteRiscoAnexo(1, 1, 'Desc1', 'arquivo1', 'nome1.pdf', ''),
      new ApetiteRiscoAnexo(2, 2, 'Desc2', 'arquivo2', 'nome2.pdf', ''));
    return observableOf(lista);
  }
}

export class OrgaoServiceMock {
  constructor() { }
  getOrgao(orgaoId: number): Observable<Orgao> {
    return observableOf(new Orgao(1449, "1449", "Orgao Mock"));
  }

  mapResultOrgao(orgaoId: number): Orgao {
    return new Orgao(1, '1', 'Orgão Teste');
  }

  getOrgaoCodigo(orgaoId: number): Observable<Orgao> {
    return observableOf(new Orgao(1449));
  }

  getOrgaoUsuario(): Observable<Orgao> {
    return observableOf(new Orgao(1449));
  }

  getSubordinados(orgaoSup: number): Observable<Orgao[]> {
    const subordinados = [];
    subordinados.push(new Orgao(1, '1', 'Orgão Teste'));
    let response = new Array();
    response = subordinados;
    return observableOf(response);
  }

  getMandatarias(): Observable<Orgao[]> {
    const mandatarias = [];
    mandatarias.push(new Orgao(1, '1', 'Orgão Mandataria Teste 1'));
    mandatarias.push(new Orgao(2, '2', 'Orgão Mandataria Teste 2'));
    let response = new Array();
    response = mandatarias;
    return observableOf(response);
  }
}

export class UsuarioMandatariaServiceMock {
  constructor() { }
  isUsuarioMandatariaGestor(cpf): Observable<boolean> {
    const response = true;
    return observableOf(response);
  }
}

export class UsuarioServiceMock {
  constructor() { }
  isUsuarioMandatariaGestor(cpf): Observable<boolean> {
    const response = true;
    return observableOf(response);
  }
}

export class MockRouter {
  navigate = jasmine.createSpy('navigate');

  navigateByUrl(url: string) { return url; }
}

export class SettingsMock {
  public UrlPcRest() {
    return 'http://localhost:8080/';
  }

  public UrlIdp() {
    return 'https://10.139.48.120:8443/idp';
  }

  public UrlSiconv() {
    return 'https://10.139.48.121:8443/siconv';
  }
}

export class MenuServiceMock {
  getMenu(): Observable<any> {
    const menu = {
      'ListaMenu':
        [
          {
            'Items':
              [
                {
                  'Label': 'Consultar Ente/Entidade', 'LabelKey': null,
                  'Funcionalidades': 'CADASTRAMENTO_CONSULTAR_PROPONENTE_MENU_CADASTRAMENTO',
                  'Action': 'https://hom.transfere.convenios.gov.br/habilitacao/consulta-entidade.html',
                  'Ambiente': null, 'Certificated': false
                }
              ],
            'Id': 'CADASTRAMENTO',
            'ItensPorColuna': 4,
            'Label': 'Cadastramento',
            'LabelKey': null,
            'Funcionalidades': null,
            'Action': null,
            'Ambiente': null,
            'Certificated': false
          }
        ],
      'UrlImagemLogo': 'https://hom.convenios.gov.br/siconv/layout/homologacao/imagens/logo_portal.png',
      'UrlLinkLogo': 'https://hom.convenios.gov.br/siconv/ForwardAction.do?modulo=Principal&path=/Principal.do',
      'UrlLinkLogout': 'https://11.123.8.11:123/idp/?LLO=true',
      'InfoUasg': '',
      'InfoConvenio': '',
      'TextoLogout': 'Sair do Sistema',
      'IdentificacaoUsuario': '123.123.123-12',
      'NomeUsuario': 'José da Silva',
      'IsUsuarioGuest': false

    };
    return observableOf(menu);
  }
}
