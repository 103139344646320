import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PcHttpClient } from './pc-http.service';
import { SettingsService } from './settings.service';
import { AtoNormativoTipo } from '../../shared/models';

@Injectable()
export class AtoNormativoTipoService {

  constructor(private http: PcHttpClient, private settings: SettingsService) {}

  getAtoNormativoTipos(): Observable<AtoNormativoTipo[]> {
    return this.http.get(this.settings.UrlPcRest() + 'api/ato-normativo');
  }

  getAtoNormativoTipo(id: number): Observable<AtoNormativoTipo> {
    return this.http.get(this.settings.UrlPcRest() + 'api/ato-normativo/' + id);
  }
}
