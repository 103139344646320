
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SimpleTimer } from 'ng2-simple-timer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { IdpService, MenuService } from '../../../core/services';
import { ModalContentComponent } from '../modal/modal.component';
import { MenuDomain, MenuItem, MenuSubItemArea } from './menu.domain';

@Component({
  selector: 'pc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  menuLoaded: Promise<boolean>;
  menuSelecionado = false;
  menuitemSelecionado: MenuItem = new MenuItem();
  itensMenuSelecionado: MenuSubItemArea[];
  menu: MenuDomain;

  dthora: string;

  minutos = 29;
  segundos = 60;
  timerId: string;
  modalRef: BsModalRef;

  constructor(private service: MenuService,
    private idpService: IdpService,
    private st: SimpleTimer,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.getMenu();
    this.getDateHour();

    this.st.newTimer('1sec', 1);
    this.timerId = this.st.subscribe('1sec', () => this.callbackSeg());
  }

  ngOnDestroy(): void {
    this.delAllTimer();
  }

  getDateHour() {
    this.dthora = new Date().toString();
  }

  callbackSeg() {
    this.segundos--;
    if (this.segundos === -1) {
      this.minutos--;
      this.segundos = 59;
    }

    if (this.minutos < 0) {
      this.delAllTimer();
      this.showLogoutModal();
    }

    if (this.minutos === 3 && this.segundos === 0) {
      this.showAvisoModal();
    }
  }

  delAllTimer() {
    this.minutos = 0;
    this.segundos = 0;
    this.st.delTimer('1sec');
  }

  showLogoutModal() {
    const initialState = {
      msg: 'A sessão encerrou. Será preciso logar novamente.',
      logout: true
    };
    this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
  }

  showAvisoModal() {
    const initialState = {
      msg: 'Esta sessão irá se encerrar em menos 3 minutos! Salve o seu trabalho para evitar perdas.',
      logout: false
    };
    this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
  }

  async recuperarMenu() {
    await this.service.getMenu().subscribe(
      (values: any) => {
        this.menu = new MenuDomain();
        const menu: MenuDomain = new MenuDomain();

        menu.urlImagemLogo = values.UrlImagemLogo;
        menu.urlLinkLogo = values.UrlLinkLogo;
        menu.urlLinkLogout = values.UrlLinkLogout;
        menu.infoUasg = values.InfoUasg;
        menu.infoConvenio = values.InfoConvenio;
        menu.textoLogout = values.TextoLogout;

        if (this.idpService.loggedUser) {
          menu.identificacaoUsuario = this.idpService.loggedUser.cpf;
          menu.nomeUsuario = this.idpService.loggedUser.nome;
        }        

        menu.isUsuarioGuest = values.IsUsuarioGuest;
        menu.itensMenu = values.ListaMenu.map(lm => {
          const menuItem: MenuItem = new MenuItem();
          menuItem.id = lm.Id;
          menuItem.itensPorColuna = lm.ItensPorColuna;
          menuItem.label = lm.Label;
          menuItem.labelKey = lm.LabelKey;
          menuItem.funcionalidades = lm.Funcionalidades;
          menuItem.action = lm.Action;
          menuItem.ambiente = lm.Ambiente;
          menuItem.certificated = lm.Certificated;
          menuItem.itens = lm.Items.map(msi => {
            const si: MenuSubItemArea = new MenuSubItemArea();
            si.label = msi.Label;
            si.labelKey = msi.LabelKey;
            si.funcionalidades = msi.Funcionalidades;
            si.action = msi.Action;
            si.ambiente = msi.Ambiente;
            si.certificated = msi.Certificated;
            return si;
          });
          return menuItem;
        });
        this.menuLoaded = Promise.resolve(true);
        this.menu = menu;
      }
    );
  }

  getMenu() {
    this.recuperarMenu();
  }

  clickMenu(menuid: string) {
    this.menuSelecionado = true;
    const itensAgrupados = [];
    for (let i = 0; i < this.menu.itensMenu.length; i++) {
      if (this.menu.itensMenu[i].id === menuid) {
        this.menuitemSelecionado = this.menu.itensMenu[i];
        break;
      }
    }
    for (let i = 0; i < this.menuitemSelecionado.itens.length; i += this.menuitemSelecionado.itensPorColuna) {
      itensAgrupados.push(this.menuitemSelecionado.itens.slice(i, i + this.menuitemSelecionado.itensPorColuna));
    }
    this.itensMenuSelecionado = itensAgrupados;
  }
}
