import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ApetiteRiscoService, IdpService, OrgaoService, PcHttpClient, MenuService, AtoNormativoTipoService } from './services';
import { SettingsService } from './services/settings.service';
import { EventButtonService } from './services/event-button.service';
import { FaixaRiscoService } from './services/faixa-risco.service';
import { ModalidadeConvenioTipoService } from './services/modalidade-convenio-tipo.service';
import { SiconvCacheService } from './services/siconv-cache.service';
import { SiconvAlertMessagesModule } from '@serpro/ngx-siconv';

@NgModule({
  imports: [
    SharedModule,
    SiconvAlertMessagesModule
  ],
  providers: [
    ApetiteRiscoService,
    EventButtonService,
    FaixaRiscoService,
    IdpService,
    OrgaoService,
    PcHttpClient,    
    MenuService,
    ModalidadeConvenioTipoService,
    AtoNormativoTipoService,
    SettingsService,
    SiconvCacheService,
    FaixaRiscoService,
  ]
})
export class CoreModule {
  constructor() {
  }
}
