import { ApetiteFaixaRisco } from "./apetite-faixa-risco";
import { ApetiteRiscoModalidade } from "./apetite-risco-modalidade";
import { Orgao } from "./orgao";
import { ApetiteRiscoAnexo } from "./apetite-risco-anexo";

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale,  } from 'ngx-bootstrap/locale';
import { CpsCurrencyPipe } from '../pipes/currency.pipe';

defineLocale('pt-br', ptBrLocale);
 
export class ApetiteRisco {
 
  constructor(    
    public id: number = null,
    public orgao: Orgao = null,
    public dtCriacaoF: string = '',
    public dtCriacao: Date = null,
    public dtRevogacao: Date = null,
        
    public douSecao: number = null,
    public douPagina: number = null,
    public douProcesso: string = '',
    public douDtPublicacao: Date = null,
    
    public atoNumero: number = null,
    public atoTipo: number = null,
    public atoNome: string = '',
    public atoDtPublicacao: Date = null,
    
    public apetiteFaixaRiscos: ApetiteFaixaRisco[] = new Array(), 
    public modalidades: ApetiteRiscoModalidade[] = new Array(),
    public anexos: ApetiteRiscoAnexo[] = new Array(),
    public orgaoF: string = '',
    public modalidadesF: string = '',
    public faixasF: string = '',
  ) { }

  public getValoresFaixaRisco() : string{ 
    let faixas: string = "";
    const pipe: CpsCurrencyPipe = new CpsCurrencyPipe();
     
    for (let index = 0; index < this.apetiteFaixaRiscos.length; index++) {
      if (this.apetiteFaixaRiscos.length > index + 1) {
        faixas = faixas + this.apetiteFaixaRiscos[index].faixaRisco.codigo
        + " (De R$ " + (this.apetiteFaixaRiscos[index].faixaRisco.valorInicial ? pipe.transform(100* this.apetiteFaixaRiscos[index].faixaRisco.valorInicial) : '0,00')
        + " até R$ " + pipe.transform(100* this.apetiteFaixaRiscos[index].faixaRisco.valorFinal) + ") \n";
      } else {
        faixas = faixas + this.apetiteFaixaRiscos[index].faixaRisco.codigo
        + " (De R$ " + (this.apetiteFaixaRiscos[index].faixaRisco.valorInicial ? pipe.transform(100* this.apetiteFaixaRiscos[index].faixaRisco.valorInicial) : '0,00')
        + " até R$ " + pipe.transform(100* this.apetiteFaixaRiscos[index].faixaRisco.valorFinal) + ")";
      }     
    }        
    return faixas;
  }
}

