import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApetiteRiscoService, IdpService } from '../../core/services';
import { ApetiteRisco, Usuario, ApetiteRiscoModalidade, ApetiteFaixaRisco, Orgao } from '../../shared/models';
import { SiconvDatePipe } from '../../shared/pipes/siconv-date.pipe';
import { AlertMessageService } from '@serpro/ngx-siconv';


@Component({
  selector: 'apetite-risco-listagem',
  templateUrl: './apetite-risco-listagem.component.html',
  styleUrls: ['./apetite-risco-listagem.component.scss']
})
export class ApetiteRiscoListagemComponent implements OnInit {

  modalRef: BsModalRef;
  deleteMessage = '';
  delApetiteId: number = null;

  listaApetite: ApetiteRisco[] = new Array();
  lista: any[] = null;
  
  usuario: Usuario = null;
  datePipe: SiconvDatePipe;

  // Grid
  export: DataExport;
  fileName: string = 'Apetites'

  constructor(
    private apetiteService: ApetiteRiscoService,
    private idpService: IdpService,
    private router: Router,
    private mensageService: AlertMessageService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.mensageService.dismissAll();
    this.datePipe = new SiconvDatePipe();
    this.apetiteService.removerApetiteCorrente();
    this.usuario = this.idpService.loggedUser;
    if (this.usuario != null) {
      this.idpService.retoken(); 
    }        
    this.listarApetite();          
  }

  listarApetite() {
    this.apetiteService.listaApetite().subscribe(
      (item: any) => {        
        if (item === null) {
          this.listaApetite = [];
        } else {
          this.listaApetite = item.map(            
            a => new ApetiteRisco(a.id,a.orgao, a.dtCriacaoF, a.dtCriacao, a.dtRevogacao, a.douSecao, a.douPagina, a.douProcesso, a.douDtPublicacao,
              a.atoNumero, a.atoTipo, a.nomeAtoNormativo, a.atoDtPublicacao,
              a.apetiteFaixaRiscos, a.modalidades, a.anexos, a.orgao.codigo +  '-' + a.orgao.nome,
              this.lerModalidades(a.modalidades), this.lerFaixasF(a.apetiteFaixaRiscos))
          );  
          
          
        }
        this.getExport();
      }
    );
  }

  getListaApetite() {
    return this.listaApetite.slice();
  }

  lerFaixasF(lista: ApetiteFaixaRisco[]){
    let faixas: string = ''; 
    if (lista) {
      
        faixas = lista[0].faixaRisco.codigo + " - " + lista[0].valorLimiteF; 
        faixas += ' / '
        faixas += lista[1].faixaRisco.codigo + " - " + lista[1].valorLimiteF; 
        
    }      
    return faixas;
  }

  lerFaixas(lista: ApetiteFaixaRisco[]){
    let faixas: string[] = new Array(); 
    if (lista) {
      for (let index = 0; index < lista.length; index++) {
        faixas.push(lista[index].faixaRisco.codigo 
          + " - " + lista[index].valorLimiteF); 
      }  
    }      
    return faixas;
  }

  lerModalidades(lista: ApetiteRiscoModalidade[]){    
    let modalidades = '';        
    for (let index = 0; index < lista.length; index++) {
      if (lista.length > index + 1) {
        modalidades = modalidades + lista[index].modalidadeConvenioTipo.tipo + ", ";          
      }else {
        modalidades = modalidades + lista[index].modalidadeConvenioTipo.tipo;  
      }     
    }        
    return modalidades;
  }

  podeManterApetite(): boolean {
    return this.usuario.isGestorConvenioConcedente() || this.usuario.isGestorFinanceiroConcedente()
    || this.usuario.isOperacionalFinanceiroConcedente() || this.usuario.isOperacionalConcedente();
  }

  podeAdicionar(): boolean {
    if (this.usuario) {
      return this.podeManterApetite();  
    } else {
      return false;
    }    
  }

  podeDeletar(orgao: Orgao): boolean {
    if (this.usuario) {
      return this.podeManterApetite() && (this.usuario.orgao.id === orgao.id || this.usuario.orgao.id === orgao.orgaoSuperior);
    } else {
      return false;
    }  
  }

  podeEditar(orgao: Orgao, dtRevogacao: any): boolean {
    if (this.usuario) {
      return this.podeManterApetite() 
      && (this.usuario.orgao.id === orgao.id || this.usuario.orgao.id === orgao.orgaoSuperior)
      && dtRevogacao === null;
    } else {
      return false;
    }       
  }

  adicionarApetite() {
    this.router.navigate(['/apetite']);
  }

  editarApetite(id) {
    this.router.navigate(['/apetite/' + id]);
  }

  detalharApetite(id){
    if (this.usuario) {
      this.router.navigate(['/apetite-detalhe/' + id]); 
    } else {
      this.router.navigate(['/acesso-livre/apetite-detalhe/' + id]); 
    }
  }

  deletarApetite(id) {
     this.apetiteService.deletarApetiteRisco(id).subscribe(
      (data) => {
        const ret = data;
        this.listarApetite();
        this.mensageService.success('Apetite Risco Excluído', 
       'Apetite Risco excluído com sucesso. Os indicadores de aprovação automatizada dos instrumentos foram reprocessados.');        
      }
    );
  }

  showConfirm(template: TemplateRef<any>, item) {
    this.delApetiteId = item.id;
    this.deleteMessage = 'Confirma a exclusão do Apetite Risco ?' ;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.delApetiteId != null) {
      this.deletarApetite(this.delApetiteId);
      this.router.navigate(['/apetite-lista']);
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }  

  getListaPaginada(listap){
    this.lista = listap;
  }
  
  getExport(): void {
    let data = []
    const columns = [
      'Órgão', 'Limite de Tolerância ao Risco', 'Tipos de Instrumento', 'Data de Criação'
    ];

    this.listaApetite.forEach(element => {
      let linha = [];
      linha.push(element.orgaoF);
      linha.push("(" + this.lerFaixas(element.apetiteFaixaRiscos) + ")");
      linha.push("(" + element.modalidadesF + ")");
      linha.push(element.dtCriacaoF);
      data.push(linha);
    });   

    this.export = new DataExport(columns, data);
  }
}

export class DataExport {
  columns: string[];
  data: any[];

  constructor(columns: string[], data: any[]){
      this.columns = columns;
      this.data = data;
  }
}