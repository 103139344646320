export class ApetiteRiscoAnexo {
  constructor(
    public id: number = null,
    public apetiteRiscoId: number = null,
    public descricao: string = null,
    public arquivo: string = null,
    public nome: string = null,
    public adtLogin: string = null,
    public situacao: string = null,
    public adtDataHora: Date = null,
    public adtOperacao: string = null,
    public tamanho: number = null
  ) { }
}
