import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, NgModel } from '@angular/forms';
import { PagesModule } from './pages/pages.module';
import { AppComponent } from './app.component';

import { PcMainComponent } from './main/main.component';
import { PcUsuarioLogadGuard } from './shared/guards/default.guard';

import { CoreModule } from './core/core.module';
import { AppConfigService } from './app.config.service';

import { NgHttpLoaderModule } from 'ng-http-loader/ng-http-loader.module';

import { defineLocale, updateLocale } from 'moment';
import localeptBR from '@angular/common/locales/br';
import localeptBRExtra from '@angular/common/locales/extra/br';

defineLocale('pt-br', localeptBR);
updateLocale('pt-br', { invalidDate: 'Data Inválida' });
registerLocaleData(localeptBR, 'pt-br', localeptBRExtra);

export function loadConfigService(configService: AppConfigService) {
  return () => {
    return configService.load();
  }; 
}

@NgModule({
  declarations: [
    AppComponent,
    PcMainComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PagesModule,
    HttpClientModule,
    NgHttpLoaderModule,
    CoreModule
  ],
  providers: [
    NgModel,
    PcUsuarioLogadGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'pt-br' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
