import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'util';
import { IdpService } from '../../core/services';

@Injectable()
export class PcUsuarioLogadGuard implements CanActivate {

  constructor(private idpService: IdpService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.idpService.loggedUser) {
      return true;
    } else {
      this.idpService.getIdpToken();
    }
    return false;
  }
}
