import { ApetiteRiscoListagemComponent } from './pages/apetite-risco-listagem/apetite-risco-listagem.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PcMainComponent } from './main/main.component';
import { PcUsuarioLogadGuard } from './shared/guards/default.guard';
import { ApetiteRiscoComponent } from './pages/apetite-risco/apetite-risco.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: PcMainComponent },
  { path: 'apetite-lista', component: ApetiteRiscoListagemComponent, canActivate: [PcUsuarioLogadGuard] },
  { path: 'apetite', component: ApetiteRiscoComponent, canActivate: [PcUsuarioLogadGuard] },
  { path: 'apetite/:id', component: ApetiteRiscoComponent, canActivate: [PcUsuarioLogadGuard] },
  { path: 'apetite-detalhe/:id', component: ApetiteRiscoComponent, canActivate: [PcUsuarioLogadGuard] },

  // ROTAS ABERTAS //
  { path: 'acesso-livre', component: ApetiteRiscoListagemComponent },
  { path: 'acesso-livre/apetite-lista', component: ApetiteRiscoListagemComponent },
  { path: 'acesso-livre/apetite-detalhe/:id', component: ApetiteRiscoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
