import { Orgao } from '.';

export class Usuario {
  constructor(
    public nome: string = '',
    public cpf: string = '',
    public email: string,
    public orgao: Orgao,
    public papeis: string[] = []
    ) {
    }

    isGestorFinanceiroConcedente(): boolean {
      return this.papeis.includes('GESTOR_FINANCEIRO_CONCEDENTE');
    }

    isGestorConvenioConcedente(): boolean {
      return this.papeis.includes('GESTOR_CONVENIO_CONCEDENTE');
    }
    isOperacionalFinanceiroConcedente(): boolean {
      return this.papeis.includes('OPERACIONAL_FINANCEIRO_CONCEDENTE');
    }

    isOperacionalConcedente(): boolean {
      return this.papeis.includes('OPERACIONAL_CONCEDENTE');
    }
  }
