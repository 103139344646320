import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({ name: 'cpsCurrency' })
export class CpsCurrencyPipe implements PipeTransform {

  DECIMAL_SEPARATOR = ',';
  THOUSANDS_SEPARATOR = '.';

  constructor() {
  }

  transform(value: number | string): string {
    value = value ? (+value).toString() : '';

    if (value.length > 2) {
      const inteiro = value.substr(0, value.length - 2);
      const fracao = this.DECIMAL_SEPARATOR + value.substr(value.length - 2, value.length);
      return (inteiro + fracao).replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
    } else if (value.length === 2) {
      const inteiro = 0;
      const fracao = this.DECIMAL_SEPARATOR + value;
      return (inteiro + fracao);
    } else if (value.length === 1) {
      const inteiro = 0;
      const fracao = this.DECIMAL_SEPARATOR + 0 + value.substr(value.length - 1, value.length);
      return (inteiro + fracao);
    }
    return value;
  }

  parse(value: string): string {
    const TAM_MAX = 11;
    value = value.replace(/\./gi, '');
    value = value.replace(/\,/gi, '');
    value = value ? (+value).toString() : '';

    if (value.length > TAM_MAX) {
      value = value.substr(0, TAM_MAX);
    }
    return value;
  }

  toBackendParse(value: number): number {
    if (!value) {
      return null;
    }
    let valor_trasformado = this.parse(value.toString());
    valor_trasformado = this.transform(valor_trasformado);
    valor_trasformado = valor_trasformado.replace(/\./gi, '');
    valor_trasformado = valor_trasformado.replace(/\,/gi, '.');
    return +valor_trasformado;
  }
}
