import { Orgao } from './../../shared/models/orgao';
import { OrgaoService } from './orgao.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../shared/models';
import { UtilToken } from '../../shared/util';
import { PcHttpClient } from './pc-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class IdpService {

  private _idpToken = null;
  private _loggedUser = null;

  constructor(
    protected http: PcHttpClient,
    private utilToken: UtilToken,
    private settings: SettingsService,
    private orgaoService: OrgaoService,
    private router: Router) { }

  get idpToken(): String {
    return this._idpToken;
  }
  set idpToken(idpToken: String) {
    this._idpToken = idpToken;
  }

  get loggedUser(): Usuario {
    return this._loggedUser;
  }
  set loggedUser(loggedUser: Usuario) {
    this._loggedUser = loggedUser;
  }

  public logout(): void {
    this.utilToken.delToken();
    this.idpToken = null;
    window.location.href = this.settings.UrlSiconv() + '?LLO=true';
  }

  private loadLoggedUser(orgao: Orgao, papeis?): Usuario {
    const jwt = this.utilToken.getToken();
    const infoToken = this.utilToken.parseJwt(jwt.token);
    let papeisUsuario;
    if (papeis) {
      papeisUsuario = papeis;
    } else {
      papeisUsuario = infoToken.papeis;
    }
    const user = new Usuario(infoToken.nome, infoToken.cpf, '', orgao, papeisUsuario);
    return user;
  }

  public getUrl(): String {
    return this.settings.UrlIdp();
  }

  getIdpToken(): void {
    //window.location.href = this.getUrl() + '/token/key?app=PC&path=' + encodeURIComponent('#/main');

    const final = this.getUrl() + '/token/key?app=PC&path=' + encodeURIComponent('#/main');
    console.log(final)
    window.location.href = final;
  }

  async getJWT(idpToken: String) {
    let jwt = null;
    this.utilToken.delToken();
    const response = await this.getIdpJwt(idpToken).toPromise();
    jwt = response;
    this.utilToken.setToken(jwt);
    const infoToken = this.utilToken.parseJwt(jwt.token);
    const orgao = await this.orgaoService.getOrgaoUsuario().toPromise();

    this.loggedUser = this.loadLoggedUser(orgao);    

    // Verificando se o usuário é um mandatariaGestor, para adicionar o papel GESTOR_MANDATARIA_CPS e evitar
    // requests futuros. O método isUsuarioMandatariaGestor da classe Usuario poderá ser chamada para essa verificacao
    // const isUsuarioGestor = await this.usuarioService.isUsuarioMandatariaGestor(infoToken.cpf).toPromise();
    // if (isUsuarioGestor) {
    //   const papeis = infoToken.papeis;
    //   papeis.push('CPS_GESTOR_MANDATARIA');
    //   this.loggedUser = this.loadLoggedUser(orgao, papeis);
    // } else {
    //   this.loggedUser = this.loadLoggedUser(orgao);
    // }

    this.router.navigate(['/apetite-lista']);
    return jwt;
  }

  getIdpJwt(idpToken: String) {
    const options = this.http.getDefaultRequestOptions();
    options.withCredentials = true;
    return this.http.getNoToken(`${this.getUrl() + '/token/jwt?token='}${idpToken}`, options);
  }

  async retoken() {
    const response = await this.getRetoken().toPromise();
    const jwt = response;
    this.utilToken.delToken();
    this.utilToken.setToken(jwt);
    return this.utilToken.getToken();
  }

  getRetoken() {
    const options = this.http.getDefaultRequestOptions();
    options.withCredentials = true;
    return this.http.get(`${this.getUrl() + '/jwt/refresh'}`, options);
  }
}
