export class FaixaRisco {
  constructor(
    public id: number = null,
    public codigo: string = null,
    public valorInicial: number = 0,
    public valorFinal: number = 0,
    public valorLimite: number = 0
  ) {   }
 
}
