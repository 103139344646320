declare var env: any;

export class SettingsService {
  public UrlPcRest() {
    return env.apiUrl();
  }

  public UrlIdp() {
    return env.idpUrl();
  }

  public UrlSiconv() {
    return env.siconvUrl();
  }
}
