
import { map } from 'rxjs/operators';
import { Orgao } from '../../shared/models/orgao';
import { OrgaoService } from '../../core/services/orgao.service';
import { ApetiteRiscoAnexo } from '../../shared/models/apetite-risco-anexo';
// Imports Angular
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
// Imports Terceiros
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApetiteRisco, Usuario, FaixaRisco, ApetiteFaixaRisco } from '../../shared/models';
import { ApetiteRiscoService, IdpService } from '../../core/services';
import { EventButtonService } from '../../core/services/event-button.service';
import { SiconvDatePipe } from '../../shared/pipes/siconv-date.pipe';
import { FaixaRiscoService } from '../../core/services/faixa-risco.service';
import { AlertMessageService } from '@serpro/ngx-siconv';

@Component({
  selector: 'apetite-risco',
  templateUrl: './apetite-risco.component.html',
  styleUrls: ['./apetite-risco.component.scss']
})
export class ApetiteRiscoComponent implements OnInit {

  modalRef: BsModalRef;
  modalMessage = '';
  funcao: any = null;

  apetiteId = null;
  apetite: ApetiteRisco = null;

  modoEdicao = true;
  orgaoUsuarioLogado: Orgao;
  usuario: Usuario = null;
  
  nomeOrgaoConcedente = '';
  codigoOrgaoConcedente = '';

  datePipe: SiconvDatePipe;
  anexos: ApetiteRiscoAnexo[] = new Array();

  constructor(
    private apetiteService: ApetiteRiscoService,
    private faixaRiscoService: FaixaRiscoService,
    private orgaoService: OrgaoService,
    private route: ActivatedRoute,
    private idpService: IdpService,
    private router: Router,
    private mensageService: AlertMessageService,
    private modalService: BsModalService
  ) {
    route.paramMap.subscribe(
      params => this.apetiteId = params.get('id')
    );
    // ativa o modo de edição se a rota de origem não for pc-detalhe
    const url: Observable<UrlSegment[]> = route.url.pipe(map(segments => segments));
    url.subscribe(value => { this.modoEdicao = value[0].path !== 'apetite-detalhe'; });
  }

  async ngOnInit() {
    this.mensageService.dismissAll();
    this.datePipe = new SiconvDatePipe();

    this.usuario = this.idpService.loggedUser;
    if (this.usuario != null) {
      this.idpService.retoken(); 
      this.usuario = this.idpService.loggedUser;
      this.orgaoUsuarioLogado = this.usuario.orgao;
    } else {
      this.modoEdicao = false;
    }

    if (this.apetiteId) {
      this.recuperarApetite(this.apetiteId);
    } else {      
      this.apetiteService.removerApetiteCorrente();      
      let listaFaixaRisco = new Array();
      await this.faixaRiscoService.getFaixasRiscos().subscribe(
        (response: FaixaRisco[]) => {          
          this.apetite = new ApetiteRisco();
          response.map(faixa => {            
            listaFaixaRisco.push(faixa);        
          });   
          listaFaixaRisco.map(faixa => {            
            this.apetite.apetiteFaixaRiscos.push(new ApetiteFaixaRisco(this.apetite.id, faixa));
          });
          this.apetiteService.apetiteCorrente = this.apetite;
      });
    }
  }

  async recuperarApetite(id) {
    await this.apetiteService.getApetite(id).subscribe((response) => {         
      this.apetiteService.apetiteCorrente = response;      
      this.apetite = this.apetiteService.apetiteCorrente;
      this.apetiteId = this.apetite.id;
      this.obterOrgaoApetite(this.apetite.orgao.id);
      EventButtonService.get('salvarApetite').emit(true);
    });
  }

  obterOrgaoApetite(orgaoId) {
    if (orgaoId) {
      this.orgaoService.getOrgao(orgaoId).subscribe(
        (response: Orgao) => {
          this.nomeOrgaoConcedente = response.nome;
          this.codigoOrgaoConcedente = response.codigo;
        }
      );
    }
  }

  existeAnexo(anexos: ApetiteRiscoAnexo[]): boolean {
    if (anexos) {
      const anexosfilter = anexos.filter(a => a.situacao !== 'E');
      return anexosfilter.length > 0;
    } else {
      return false;
    }
  }

  onSalvar(template: TemplateRef<any>) {
    this.mensageService.dismissAll();
    

    if (this.validar(this.apetiteService.apetiteCorrente)) {
      //se for edicao, nao confirma criacao de novo
      if (this.apetiteId){
        this.salvar();        
      } else {
        if (!this.apetiteService.apetiteCorrente.orgao){
          this.mensageService.error('Campo obrigatório', 
          'O Órgão é obrigatório');  
        } else {
            this.apetiteService.getApetitePorOrgao(this.apetiteService.apetiteCorrente.orgao.id).subscribe(
              (ap) => {
                if(ap) {
                  this.confirmaInclusao(template);
                } else {
                  this.salvar();
                }
              }
            );    
        }           
      }
    }    
  }

  async salvar() { 
    await this.apetiteService.salvarApetite(this.anexos).subscribe(
      (id: number) => {     
          this.recuperarApetite(id)
          if (id) {           
             this.mensageService.success('Apetite Risco Salvo', 
            'Apetite Risco salvo com sucesso. Os indicadores de aprovação automatizada dos instrumentos foram reprocessados com as novas definições do apetite ao risco.');            
           }
        }
       );    
  }

    
  private validar(apetite: ApetiteRisco): Boolean {
    let retorno: Boolean = true;    
    if (!this.existeAnexo(this.anexos)){
      this.mensageService.error('Campo obrigatório', 
          'O Anexo é obrigatório');  
          return false;
    }
    return retorno? this.validarAnexos(this.anexos) : retorno;
  }

  validarAnexos(listaAnexos: ApetiteRiscoAnexo[]): boolean {
    let retorno = true;
    let tamanhoAnexos = 0;
    listaAnexos.forEach(anexo => {
      if ((anexo.tamanho) && (anexo.situacao === 'N')) {
        tamanhoAnexos += anexo.tamanho;
      }
    });
    if (tamanhoAnexos / 1000000 > 25) {
      this.mensageService.error('Tamanho de Arquivo Inválido', 
      'O somatório do tamanho dos arquivos anexados excede o limite de 25MB');      
      retorno = false;
    }
    return retorno;
  }

  limpaMensagem(){
    this.mensageService.dismissAll();
  }


  podeManterApetite(): boolean {
    if (this.usuario) {
      return this.usuario.isGestorConvenioConcedente() || this.usuario.isGestorFinanceiroConcedente()
    || this.usuario.isOperacionalFinanceiroConcedente() || this.usuario.isOperacionalConcedente();
    } else {
      return false;
    } 
  }

  confirmaInclusao(template: TemplateRef<any>){
    this.modalMessage = 'O Apetite ao Risco atual será revogado. Confirma a inclusão do Apetite ao Risco?';
    this.funcao = this.salvar;    
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    if (this.funcao != null) {
      if (typeof (this.funcao) === 'function') {
        this.funcao.call(this);
      }
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  setAnexos(event: any) {
    this.anexos = event;
  }

  listarApetite(){
    if (this.usuario) {
      this.router.navigate(['/apetite-lista']); 
    } else {
      this.router.navigate(['/acesso-livre/apetite-lista']); 
    }
  }  
}
