import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaixaRisco } from '../../shared/models';
import { PcHttpClient } from './pc-http.service';
import { SettingsService } from './settings.service';

@Injectable()
export class FaixaRiscoService {

  constructor(private http: PcHttpClient, private settings: SettingsService) {}

  getFaixasRiscos(): Observable<FaixaRisco[]> {
    return this.http.get(this.settings.UrlPcRest() + 'api/faixa-risco');
  }

  getFaixaRisco(id: number): Observable<FaixaRisco> {
    return this.http.get(this.settings.UrlPcRest() + 'api/faixa-risco/' + id);
  }
}
