import { FaixaRisco } from "./faixa-risco";

export class ApetiteFaixaRisco {
    constructor(
        public apetiteRiscoId: number = null,
        public faixaRisco: FaixaRisco = null,
        public valorLimite: number = null,
        public valorLimiteF: string = '',
        public valorLimiteAuxiliar: string = ''
    ) { }
}
