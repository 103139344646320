import { Injectable } from '@angular/core';

@Injectable()
export class UtilToken {

    private appToken = 'appToken';

    private salvar(content: Object) {
      sessionStorage.setItem(this.appToken, JSON.stringify(content));
    }

    private remover() {
        sessionStorage.removeItem(this.appToken);
    }

    private recuperar() {
        const sessionStorageToken = sessionStorage.getItem(this.appToken);
        return sessionStorageToken;
    }

    public parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }

    public setToken(jwt: Object) {
        this.salvar(jwt);
    }

    public delToken() {
        this.remover();
    }

    public getToken() {
        const currentTime: number = (new Date()).getTime();
        let token = null;
        try {
            const sessionStorageToken = JSON.parse(this.recuperar());
            token = sessionStorageToken;
        } catch (err) {
            console.error(err);
        }
        return token;
    }
}
