
import {filter} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from 'util';
import { UtilToken } from '../shared/util';
import { IdpService } from '../core/services';
import { SettingsService } from '../core/services/settings.service';

@Component({
  selector: 'pc-main',
  templateUrl: './main.component.html'
})
export class PcMainComponent implements OnInit {

  inscricao: Subscription;

  constructor(
    private route: ActivatedRoute,
    private idpService: IdpService,
    private utilToken: UtilToken,
    private settings: SettingsService
  ) { }

  ngOnInit() {
    let idpToken = null;
    this.inscricao = this.route.queryParams.pipe(filter(params => params.token)).subscribe(
      params => {
        idpToken = params.token;
      }
    );
    if (idpToken === '' || idpToken == null || idpToken === undefined) {
      this.idpService.getIdpToken();
    } else {
      this.idpService.idpToken = idpToken;
      this.idpService.getJWT(idpToken);
    }
  }

  realizarLogout(): void {
    this.utilToken.delToken();
    this.idpService.idpToken = null;
    window.location.href = this.settings.UrlIdp();
  }
}
