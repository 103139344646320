import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { log } from 'util';

@Pipe({
  name: 'dateFormatBr'
})
export class DateFormatBrPipe implements PipeTransform {
  transform(value: any): any {    
    if (value != null && value !== '') {
      if (typeof value === 'object') {
        const ano = value.year;
        const mes = value.monthValue;
        const dia = value.dayOfMonth;
        return dia + '-' + mes + '-' + ano;
      } else {
        const ano = value.substring(0, 4);
        const mes = value.substring(5, 7);
        const dia = value.substring(8, 10);
        return dia + '-' + mes + '-' + ano;      
      }
    }
    return null;
  }

  toServerDate(value: any): any {
    if (value != null && value !== '') {
      let ano, mes, dia = '';
      if (value[4] === '-') {
        ano = value.substring(0, 4);
        mes = value.substring(5, 7);
        dia = value.substring(8, 10);
      } else {
        dia = value.substring(0, 2);
        mes = value.substring(3, 5);
        ano = value.substring(6, 10);
      }
      return ano + '-' + mes + '-' + dia;
    }
    return null;
  }
}

