import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PcHttpClient } from './pc-http.service';
import { ApetiteRisco, ApetiteRiscoAnexo } from '../../shared/models';
import { SettingsService } from './settings.service';
import { HttpHeaders } from '@angular/common/http';
import { DateFormatBrPipe } from '../../shared/pipes/date-format-br.pipe';

@Injectable()
export class ApetiteRiscoService {

  private _apetite: ApetiteRisco;

  constructor(protected http: PcHttpClient, private settings: SettingsService) { }

  public getUrl(): string {
    return this.settings.UrlPcRest() + 'api/apetite';
  }

  get apetiteCorrente(): ApetiteRisco {
    let apetite: ApetiteRisco;
    if (sessionStorage.length > 0) {
      apetite = JSON.parse(sessionStorage.getItem('apetite-corrente'));
      this._apetite = apetite;
    } else {
      this._apetite = null;
    }
    return this._apetite;
  }

  set apetiteCorrente(apetite: ApetiteRisco) {
    sessionStorage.setItem('apetite-corrente', JSON.stringify(apetite));
    this._apetite = apetite;    
  }

  public removerApetiteCorrente() {
    sessionStorage.removeItem('apetite-corrente');
  }

  salvarApetite(anexos?: ApetiteRiscoAnexo[]): Observable<number> {    
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    if (this.apetiteCorrente.id > 0) {    
      return this.http.put(`${this.getUrl()}`, this.toServer(anexos), { headers: headers });
    } else {
      return this.http.post(`${this.getUrl()}`, this.toServer(anexos), { headers: headers });
    }
  }

  getApetite(id): Observable<ApetiteRisco> {
    return this.http.get(`${this.getUrl() + '/'}${id}`);
  }

  getApetitePorOrgao(orgaoId): Observable<number> {
    return this.http.get(`${this.getUrl()}` + '/vigente/' + orgaoId );
  }


  listaApetite(): Observable<ApetiteRisco[]> {
    return this.http.get(`${this.getUrl()}`);
  }

  listaApetitePorOrgao(orgaoId): Observable<ApetiteRisco[]> {
    return this.http.get(`${this.getUrl()}` + '/orgao/' + orgaoId);
  }

  deletarApetiteRisco(id): Observable<number> {
    return this.http.delete(`${this.getUrl() + '/'}${id}`);
  }

  listarAnexos(id): Observable<ApetiteRiscoAnexo[]> {    
    return this.http.get(`${this.getUrl() + '/'}${id}` + '/anexos');
  }

  baixarAnexo(id, id_anexo): Observable<ApetiteRiscoAnexo[]> {
    return this.http.get(`${this.getUrl() + '/'}${id}` + '/anexos/' + `${id_anexo}`);
  }

  getAnexos(listaAnexos: any): ApetiteRiscoAnexo[] {
    let anexos: ApetiteRiscoAnexo[] = null;
    if (!listaAnexos) {
      return null;
    } else {      
      anexos = listaAnexos.filter(a => (a.situacao === 'N') || (a.id && a.situacao === 'E'));      
    }
    return anexos;
  }

  toServer(array_anexos?: any): any {
    const apetite = this.apetiteCorrente;
    const datePipe = new DateFormatBrPipe();

    return {
      id: apetite.id,
      orgao: apetite.orgao,      
      douSecao: apetite.douSecao,
      douPagina: apetite.douPagina,
      douProcesso: apetite.douProcesso,
      douDtPublicacao: datePipe.toServerDate(apetite.douDtPublicacao),
      atoNumero: apetite.atoNumero,
      atoTipo: apetite.atoTipo,
      atoNome: apetite.atoNome,
      atoDtPublicacao: datePipe.toServerDate(apetite.atoDtPublicacao),
      anexos: this.getAnexos(array_anexos),
      apetiteFaixaRiscos: apetite.apetiteFaixaRiscos,
      modalidades: apetite.modalidades
    };
  }
}
