import { Pipe, PipeTransform } from '@angular/core';
import { ZeroEsquerdaPipe } from './zero-esquerda.pipe';

@Pipe({
  name: 'siconvDate'
})
export class SiconvDatePipe implements PipeTransform {
  zero: ZeroEsquerdaPipe = new ZeroEsquerdaPipe();
  transform(value: any): any {        
    if (typeof value === 'object') {      
      const ano = value.year;
      const mes = value.monthValue;
      const dia = value.dayOfMonth;
      return this.zero.transform(dia, 2) + '/' + this.zero.transform(mes, 2) + '/' + this.zero.transform(ano, 2);
    } else {
      const data = value.toString().split('-');
      const ano = data[0];
      const mes = data[1];
      const dia = data[2];
      return this.zero.transform(dia, 2) + '/' + this.zero.transform(mes, 2) + '/' + this.zero.transform(ano, 2);
    }
    return null;
  }
}

