import { PipeTransform, Pipe } from "@angular/core";
import { Orgao, AtoNormativoTipo } from "../models";

@Pipe({
  name: 'selectOrgao'
})
export class SelectOrgaoPipe implements PipeTransform {
  transform(orgaos: Orgao[]): string[] {
    return orgaos.map(orgao => orgao.codigo + " - " + orgao.nome);
  }
}

@Pipe({
  name: 'selectAto'
})
export class SelectAtoPipe implements PipeTransform {
  transform(atos: AtoNormativoTipo[]): string[] {
    return atos.map(ato => ato.tipo);
  }
}